import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import Header from '../components/Shared/Header'

const mapStateToProps = (state) => {
  return {
    isAuth: state.merchant.isAuth,
    selectedStore: state.company.store
  }
}

const PrivateRoute = ({ component: Component, isAuth, selectedStore }) => {
  return isAuth ? (
    <div
      style={{
        backgroundImage: selectedStore.store_background
          ? `url('${selectedStore.store_background}')`
          : '',
        backgroundSize: 'cover'
      }}>
      <Header selectedStore={selectedStore} />
      <Component />
    </div>
  ) : (
    <Navigate to="/" />
  )
}

export default connect(mapStateToProps, null)(PrivateRoute)
