import React from 'react'
import { motion } from 'framer-motion'
import OrdersUtils from '../../utils/OrdersUtils'

import './../../styles/Order.scss'

const ANIMATION_DEFAULT = {
  INITIAL: { x: -2000, opacity: 0 },
  ANIMATE: { x: 0, opacity: 1 },
  TRANSITION: { duration: 0.5 }
}

const Order = React.memo(({ order, animation = ANIMATION_DEFAULT, modalVariant = false }) => {
  return (
    <motion.div
      className={`order-wrapper`}
      initial={animation.INITIAL}
      animate={animation.ANIMATE}
      transition={animation.TRANSITION}>
      <div className="order">
        <div className="header">
          <div className="top">
            <div className="left">
              <h2>{OrdersUtils.getName(order).slice(-3)}</h2>
            </div>

            {(modalVariant || order.extras?.orderType) && (
              <div className="right">
                {modalVariant && (
                  <span
                    style={{
                      color: '#fff',
                      fontWeight: 'bold'
                    }}
                    className={`bg-status-default bg-${order.status}`}>
                    {OrdersUtils.renderStatus(order.status)}
                  </span>
                )}
                <p>{order.extras.orderType}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  )
})

export default Order
