import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import configureStore from './store/configureStore'
import LoginPage from './Routes/LoginPage'
import PrivateRoute from './Routes/PrivateRoute'
import RegistrationPage from './Routes/RegistrationPage'
import MainPage from './Routes/MainPage'

import { InternationalizationProvider } from 'react-internationalization'
import * as languages from './translations'

import './styles/App.scss'

const store = configureStore()

const lang =
  localStorage.getItem('ODS:Language') !== null ? localStorage.getItem('ODS:Language') : 'nl'

export default function AppRouter() {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <InternationalizationProvider defaultLanguage={lang} languages={languages}>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/registration" element={<RegistrationPage />} />
              <Route path="/main" element={<PrivateRoute component={MainPage} />} />
            </Routes>
          </InternationalizationProvider>
        </BrowserRouter>
      </CookiesProvider>
    </Provider>
  )
}
