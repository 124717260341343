import { STATUS, TRACKING_STATUS } from './CONSTS'
import { translate } from 'react-internationalization'

export default class OrdersUtils {
  static getName = (order) => {
    let result

    // Check if extras.delivery.channelName is available, and construct a result
    if (order.extras?.delivery?.channelName) {
      result = `${order.extras.delivery.channelName} ${order.extras.delivery.channelOrderDisplayId}`
    }
    // Check for a specific condition related to Transaction orders
    else if (
      order?.__t === 'Transaction' &&
      order.receipt_id.indexOf('ORDER') < 0 &&
      !['kiosk', 'external_order'].includes(order.order_source)
    ) {
      // Check for conditions related to order_source and receipt_id
      if (order.receipt_id.indexOf('ORDER') < 0 && order.receipt_id.length > 3) {
        // Use receipt_id if certain conditions are met
        result = order.receipt_id
      } else if (order.extras?.asposOrder?.orderNumber) {
        // Use extras.deviceCartName if available
        result = order.extras.deviceCartName.trim()
      } else {
        // Use 'ORDER' as the default result
        result = 'ORDER'
      }
    }
    // Check if is an ASPOS kassabon
    else if (order.extras?.asposOrder?.customerReference?.indexOf('KASSABON:') >= 0) {
      result = order.extras.asposOrder.customerReference.trim().slice(-3)
    // Check if extras.orderNumber is available
    } else if (order.extras?.asposOrder?.orderNumber) {
      // Use extras.orderNumber, trimmed and sliced if available
      result = order.extras.asposOrder.orderNumber.trim().slice(-3)
    }
    // If none of the conditions above match, use order.receipt_id as the result
    else {
      result = order.receipt_id
    }

    // Return the calculated result
    return result
  }

  static getDate = (date) => {
    const now = new Date(date)
    return now.toLocaleDateString('en-GB')
  }

  static getTime = (date) => {
    const now = new Date(date)
    return now.toLocaleTimeString('en-GB')
  }

  static getStatusCount = (orders, status) => {
    if (orders) {
      return orders.reduce((acc, order) => {
        return acc + (order.status && order.status === status ? 1 : 0)
      }, 0)
    }
  }

  static getOrderStatus = (order) => {
    switch (order.status) {
      case STATUS.NEW:
        return STATUS.NEW
      case STATUS.PENDING:
      case STATUS.PRINTED:
        return STATUS.PRINTED
      case STATUS.READY:
        return STATUS.READY
      case STATUS.COMPLETED:
        return STATUS.COMPLETED
      case STATUS.CANCELLED:
        return STATUS.CANCELLED
      default:
        return STATUS.PREPARING
    }
  }

  static getOrderStatusFromRedux = (orders, orderId, payload) => {
    if (!!payload?.status) {
      switch (payload.status) {
        case STATUS.NEW:
          return STATUS.NEW
        case STATUS.PENDING:
        case STATUS.PRINTED:
          return STATUS.PRINTED
        case STATUS.READY:
          return STATUS.READY
        case STATUS.COMPLETED:
          return STATUS.COMPLETED
        case STATUS.CANCELLED:
          return STATUS.CANCELLED
        default:
          return STATUS.PREPARING
      }
    }

    let st = TRACKING_STATUS.PRINTED
    Object.keys(TRACKING_STATUS).forEach((stateKey) => {
      const index = orders[TRACKING_STATUS[stateKey]].findIndex((o) => o._id === orderId)
      if (index >= 0) {
        st = this.getOrderStatus(orders[TRACKING_STATUS[stateKey]][index])
      }
    })

    return st
  }

  static getOrdersFromStatus = (orders, st) => {
    return [...orders].filter((order) => {
      return this.getOrderStatus(order) === st
    })
  }

  static renderStatus = (status) => {
    if (!!status && !!status.length) {
      let finalStatus
      const localStatus =
        status === STATUS.PENDING || status === STATUS.PRINTED ? STATUS.NEW : status
      switch (localStatus) {
        case STATUS.NEW:
        case STATUS.PENDING:
          finalStatus = translate('new')
          break
        case STATUS.PRINTED:
          finalStatus = translate('new')
          break
        case STATUS.PREPARING:
          finalStatus = translate('preparing')
          break
        case STATUS.READY:
          finalStatus = translate('ready')
          break
        case STATUS.COMPLETED:
          finalStatus = translate('ready')
          break
        case STATUS.CANCELLED:
          finalStatus = translate('cancelled')
          break
        default:
          finalStatus = localStatus
      }
      return finalStatus
    } else {
      return STATUS.NEW
    }
  }
}
