import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import StoreUtils from '../utils/StoreUtils'
import { motion } from 'framer-motion'
import { setStoreDevice } from '../store/actions/company'
import Logo from '../components/Shared/Logo'
import Input from '../components/Shared/Input'
import Button from '../components/Shared/Button'
import './../styles/Login.scss'
import SelectionList from '../components/Shared/SelectionList'
import DeviceUtils from '../utils/DeviceUtils'

const mapStateToProps = (state) => {
  return {
    uuid: state.company.deviceUUID
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStoreDevice: (device) => dispatch(setStoreDevice(device))
  }
}

const RegistrationPage = ({ uuid, setStoreDevice }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [deviceName, setDeviceName] = useState('')
  const [stores, setStores] = useState([])
  const [storeId, setStoreId] = useState('')

  const registrationSuccess = (device) => {
    setStoreDevice(device)
    setIsLoading(false)
    navigate('/main')
  }

  const registrationError = (error) => {
    console.log('RegistrationPage -> error', error)
    setIsLoading(false)
  }

  const loadStores = useCallback(async () => {
    const sts = await StoreUtils.getStores().catch((error) => registrationError(error))
    setStores(sts)
    setStoreId(sts[0]._id)
  }, [])

  useEffect(() => {
    if (!uuid) {
      navigate('/')
    } else {
      loadStores()
    }
  }, [loadStores, navigate, uuid])

  const registerDevice = () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    DeviceUtils.registerDevice(uuid, storeId, deviceName)
      .then((device) => registrationSuccess(device))
      .catch((error) => registrationError(error))
  }

  return (
    <div className="login-page">
      <motion.div
        className="card"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}>
        <Logo />
        <Input type="text" label="Device Name" value={deviceName} onChange={setDeviceName} />
        <SelectionList
          label="Select Store"
          options={stores}
          value={storeId}
          onChange={setStoreId}
        />
        <Button label="Register Device" onClick={registerDevice} isLoading={isLoading} />
      </motion.div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage)
