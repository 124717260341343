import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import Order from './Order'
import ReadyOrderAlert from './ReadyOrderAlert'
import { STATUS } from '../../utils/CONSTS'
import { translate } from 'react-internationalization'

// import NOTIFICATION from './../../assets/sounds/notification.mp3'
import './../../styles/Main.scss'

const mapStateToProps = (state) => {
  return {
    orders: state.orders
  }
}

const TIMEOUT = 10000

const StatusSectionList = React.memo(({ orders, status }) => {
  // const audio = useRef(null)
  const [count, setCount] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [showOrder, setShowOrder] = useState({})

  const removeOrderAlert = () => {
    setShowAlert(false)
    setShowOrder({})
  }

  const shouldShowReadyAlert = useCallback(() => {
    return (
      status === STATUS.READY &&
      orders[STATUS.READY].length > 0 &&
      orders[STATUS.READY].length >= count
    )
  }, [count, orders, status])

  const addOrderAlert = useCallback(() => {
    setShowOrder(orders[STATUS.READY][0])
    setShowAlert(true)
    setTimeout(removeOrderAlert, TIMEOUT)

    // TODO: It is having an error that DOM was not interact before play the sound
    // audio.current.currentTime = 0
    // audio.current.play()
  }, [orders])

  useEffect(() => {
    if (shouldShowReadyAlert()) {
      addOrderAlert()
    }

    setCount(orders[STATUS.READY].length)
  }, [addOrderAlert, orders, shouldShowReadyAlert])

  const mergeOrders = (status) => {
    if (status === 'printed' || status === 'preparing') {
      return [...orders.printed, ...orders.preparing]
    }

    return orders[status]
  }

  return (
    <div className="status-section-wrapper">
      <p className="bg-status-title">
        <span className={`bg-status-default bg-${status}`}>{translate(status)}</span>
      </p>
      <div className="status-section" key={status}>
        {mergeOrders(status).map((order) => {
          return <Order key={order._id} order={order} status={status} />
        })}
        {showAlert && <ReadyOrderAlert order={showOrder} closeAlert={removeOrderAlert} />}
        {/* <audio ref={audio} src={NOTIFICATION}></audio> */}
      </div>
    </div>
  )
})

export default connect(mapStateToProps, null)(StatusSectionList)
