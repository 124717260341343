import React, { useEffect, useCallback } from 'react'
import Logo from './Logo'
import { LOCAL_STORAGE, THEME } from '../../utils/CONSTS'
import './../../styles/App.scss'

const Header = React.memo(({ selectedStore }) => {
  const _now = React.useMemo(() => new Date(), [])
  const day = React.useMemo(
    () => (_now.getDate() < 10 ? '0' + _now.getDate() : _now.getDate()),
    [_now]
  )
  const _month = React.useMemo(() => _now.getMonth() + 1, [_now])
  const month = React.useMemo(() => (_month < 10 ? '0' + _month : _month), [_month])

  const clock = useCallback(() => {
    const now = new Date()
    const el = document.getElementById('clock')

    if (!!el) {
      const hour = now.getHours()
      const min = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()
      // const sec = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds()
      // el.innerHTML = `${hour}:${min}:${sec}`
      el.innerHTML = `${hour}:${min}`
    }
    setTimeout(clock, 1000)
  }, [])

  useEffect(() => {
    clock()
    const theme = localStorage.getItem(LOCAL_STORAGE.THEME)
    if (theme === THEME.LIGHT) {
      changeTheme()
    }
  }, [clock])

  const changeTheme = () => {
    document.body.classList.toggle(THEME.LIGHT)
    document.querySelector(THEME.APP_HEADER).classList.toggle(THEME.LIGHT)
    document.querySelector(THEME.LOGO).classList.toggle(THEME.LIGHT)
    document.querySelector(THEME.CLOCK).classList.toggle(THEME.LIGHT)
    // Apply also change theme to orders style
    document.querySelectorAll(THEME.PRINTED).forEach((el) => {
      el.classList.toggle(THEME.LIGHT)
    })
    document.querySelectorAll(THEME.PREPARING).forEach((el) => {
      el.classList.toggle(THEME.LIGHT)
    })
    document.querySelectorAll(THEME.READY).forEach((el) => {
      el.classList.toggle(THEME.LIGHT)
    })

    localStorage.setItem(
      LOCAL_STORAGE.THEME,
      document.body.classList.contains(THEME.LIGHT) ? THEME.LIGHT : THEME.DARK
    )
  }

  return (
    <header id="app-header" className="app-header">
      <div className="filter-icons-header">
        <Logo selectedStore={selectedStore} />
        <div className="clock">
          <span>{day + '/' + month}</span>
          <span id="clock"></span>
        </div>
      </div>
    </header>
  )
})

export default Header
