import { SET_ORDERS, ADD_ORDER, UPDATE_ORDER, REPLACE_ORDER } from './../actions/actionTypes'
import OrdersUtils from './../../utils/OrdersUtils'
import { STATUS } from './../../utils/CONSTS'

const initialState = {
  new: [],
  pending: [],
  printed: [],
  preparing: [],
  ready: [],
  completed: [],
  cancelled: []
}

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        printed: OrdersUtils.getOrdersFromStatus(action.payload, STATUS.PRINTED),
        pending: OrdersUtils.getOrdersFromStatus(action.payload, STATUS.PENDING),
        preparing: OrdersUtils.getOrdersFromStatus(action.payload, STATUS.PREPARING),
        ready: OrdersUtils.getOrdersFromStatus(action.payload, STATUS.READY),
        completed: OrdersUtils.getOrdersFromStatus(action.payload, STATUS.COMPLETED),
        new: OrdersUtils.getOrdersFromStatus(action.payload, STATUS.NEW),
        cancelled: OrdersUtils.getOrdersFromStatus(action.payload, STATUS.CANCELLED)
      }
    case ADD_ORDER:
      const status = OrdersUtils.getOrderStatus(action.payload)
      return {
        ...state,
        [status]: [action.payload].concat(state[status])
      }
    case UPDATE_ORDER:
      const currentStatus = OrdersUtils.getOrderStatusFromRedux(state, action.payload._id, null)
      const newStatus = OrdersUtils.getOrderStatus(action.payload)

      if (!currentStatus) {
        return {
          ...state,
          [newStatus]: [action.payload].concat(state[newStatus])
        }
      } else if (currentStatus !== newStatus) {
        return {
          ...state,
          [currentStatus]: [...state[currentStatus]].filter(
            (order) => order._id !== action.payload._id
          ),
          [newStatus]: [action.payload].concat(state[newStatus])
        }
      } else if (currentStatus === newStatus) {
        const index = state[currentStatus].findIndex((order) => order._id === action.payload._id)
        return {
          ...state,
          [currentStatus]:
            index >= 0
              ? [...state[currentStatus]].map((order) =>
                  order._id === action.payload._id ? action.payload : order
                )
              : [action.payload].concat(state[currentStatus])
        }
      } else {
        return state
      }
    case REPLACE_ORDER:
      const sourceId = action.payload.extras.orderSource
      const statusCurrentCart = OrdersUtils.getOrderStatusFromRedux(state, sourceId, action.payload)
      const index = state[statusCurrentCart].findIndex((order) => order._id === sourceId)
      return {
        ...state,
        [statusCurrentCart]:
          index >= 0
            ? [...state[statusCurrentCart]].map((order) =>
                order._id === sourceId ? action.payload : order
              )
            : [action.payload].concat([...state[statusCurrentCart]])
      }
    default:
      return state
  }
}

export default ordersReducer
