import { Countr } from './../CountrSDK'
const DEFAULT_SORT = '-updated_at'

export default class CountrResources {
  static getResource = async (
    resource,
    limit = process.env.REACT_APP_API_LIMIT,
    sort = DEFAULT_SORT
  ) => {
    const countr = await Countr.getCountrSDK()

    return countr[resource].read({ limit, sort }).then(
      (res) => {
        return Promise.resolve(res)
      },
      (error) => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  static getResourceDetails = async (resource, id) => {
    const countr = await Countr.getCountrSDK()
    return countr[resource].readOne(id).then(
      (res) => {
        return Promise.resolve(res)
      },
      (error) => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  static updateResource = async (resource, item) => {
    const countr = await Countr.getCountrSDK()
    return countr[resource].update(item._id, item).then(
      (res) => {
        return Promise.resolve(res)
      },
      (error) => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  static getDailyOrders = async (token, date, resource, store) => {
    if (!date) {
      return
    }

    date = date.toISOString()

    const countr = await Countr.getCountrSdk()

    const result = await countr[resource].read({
      filter: {
        date: date,
        store: store
      },
      type: {
        date: 'date',
        store: 'ObjectId'
      },
      operator: {
        date: '$gte',
        store: '$eq'
      },
      limit: process.env.REACT_APP_API_LIMIT
    })

    return result
  }
}
